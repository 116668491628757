import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

import Sakralraum1 from "../components/sakralraum1"
import Sakralraum2 from "../components/sakralraum2"
import Sakralraum3 from "../components/sakralraum3"
import Sakralraum4 from "../components/sakralraum4"

import "./index.css"

export default function SakralraumPage() {
  return (
    <>
      <SEO
        description="restaura ratisbona, Ihr Team für Restaurierungen. Sakralraum"
        title="restaura ratisbona, Ihr Team für Restaurierungen. Sakralraum"
        lang="de"
      />
      <Header />
      <section>
        <h1 className="indexHeadline">Sakralraum</h1>
        <h2 className="referenceH2">
          Pfarrkirche Mariae Himmelfahrt, Beidl Lkr. Tirschenreuth Raumschale
          und Wandmalerei im Chorraum und Langhaus
        </h2>
        <h3 className="referenceH3">
          Riss- und Hohlstellenhinterfüllung, Konservierung hochstehender
          Malschicht an ölgebundenen Deckengemälden von 1890, Retusche von
          Fehlstellen am Deckengemälde, Untersuchung der Raumschalenfassung,
          Rekonstruktion der Raumschalenfassung von 1890 entsprechend der
          Befundlage
        </h3>
        <div className="referencePageDiv">
          <div className="referenceImages">
            <label htmlFor="Sakralraum1">
              <Sakralraum1 id="Sakralraum1" />
              Raumschale im Chorraum nach der Wiederherstellung der Fassung von
              1890
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Sakralraum2">
              <Sakralraum2 id="Sakralraum2" />
              Malerei im Chorgewölbe nach der Restaurierung mit zeitgleicher
              Farbgebung der Stuckrahmen und der Deckensegel
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Sakralraum3">
              <Sakralraum3 id="Sakralraum3" />
              Nördliche Kirchenschiffwand, Rekonstruktion der
              Architekturoberflächen der Skulpturen nach Befund
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Sakralraum4">
              <Sakralraum4 id="Sakralraum4" />
              Deckengemälde von 1890 im Kirchenschiff nach der Rekonstruktion
              zeitgleicher Farbgebung der Raumschale. Die Gemälderahmen im
              Kirchenschiff wurden in illusionistischer Manier gemalt.
              <br />
              (Foto: Astrid Mendes)
            </label>
          </div>
          <div className="referenceTextDiv">
            <h4 className="referenceH4">Geschichte und Bedeutung</h4>
            <p className="referenceText">
              Die Pfarrkirche Maria Himmelfahrt in Beidl bei Plößberg erbaute in
              den Jahren 1727 bis 1738 der Waldsassener Baumeister Philipp
              Muttone. Es zeigte sich, dass die Geschichte der Pfarrei mit dem
              Kloster Waldsassen seit 1297 eng verbunden war, sodass dem Kloster
              das Patronatsrecht über die Pfarrei übertragen worden war. Im 18.
              Jahrhundert war die Kirche ein wichtiger Ort der sieben
              Gebetsziele auf der böhmischen „Königsberger Prozession“. Damals
              waren bis 8.000 Wallfahrer an sechs Tagen zu acht Wallfahrtsorten
              unterwegs. Die dargestellten Malereiszenen in den Deckenflächen
              behandeln Stationen aus dem Marienleben.
            </p>

            <h4 className="referenceH4">Erhaltungszustand / Befundsituation</h4>
            <p className="referenceText">
              Die ursprüngliche barocke Farbfassung war nicht mehr vorhanden und
              die ehemaligen Deckengemälde sind übermalt worden. Die heute
              sichtbare Malerei wurde im ausgehenden 19. Jahrhundert im
              neobarocken Stil mit einem ölhaltigen Bindemittel geschaffen. Der
              technologische Befund ergab, dass man 1890 einzelne Profile der
              Stuckrahmen im Chor vergoldete und mit gelben und braunen Linien
              versah. Die Profile der Stichkappen wurden nach demselben Schema
              gefasst. Die Gewölberücklagen und Wände trugen in dieser Phase
              eine(m)n hellen Ockerton. In der zurückliegenden
              Restaurierungsphase von 1979/1980 hat man Raumschale mit einem
              dispersionshaltigen Anstrich versehen.
            </p>

            <h4 className="referenceH4">Restauratorische Zielstellung</h4>
            <p className="referenceText">
              Im Vordergrund stand die Konsolidierung des Gewölbes. Umfangreiche
              Hinterfüllung und Rissschließung waren durchzuführen. Die durch
              die Ölfarbe zwangsläufig auftretende Schollenbildung der
              Gemäldemalschicht musste behoben werden. Da die Weißschleier die
              Bildaussage beeinträchtigten, sollte ein partieller Überzug
              aufgebracht werden. Da die Deckengemälde, das Hochaltarbild und
              die Kreuzwegstationen dem Stil des 19. Jahrhunderts entsprechen,
              führte dies zu der Überlegung, nachdem der Befund eine lückenlose,
              detaillierte Fassung von 1890 nachweisen hatte können, die
              zeitgleiche Raumschalenfassung, die mit dem Deckengemälden
              entstand, wiederherzustellen. Nach der Befunderhebung im
              Hauptschiff konnte auch dort die Farbfassung von 1890 nachgewiesen
              werden. Hinzukommt, dass die Fassung der zurückliegenden
              Restaurierung spannungsreich war, deshalb sollte ein
              Bindemittelsystem ausgewählt werden, das einen Ausgleich schaffte.
              Dieses Konzept wurde mit den Vertretern der Denkmalpflege und der
              Kirchenverwaltung festgelegt.
            </p>

            <h4 className="referenceH4">Maßnahmen</h4>
            <p className="referenceText">
              Mit der Hinterfüllung im Deckenbereich konnte eine Stabilität
              erreicht werden. Die Risskittungen und Hinterspritzpunkte wurden
              mit einer Strichretusche geschlossen. Die hochstehenden
              Farbschollen an den Deckengemälden wurden gefestigt. Außerdem
              konnte die Weißschleierbildung an der Bildoberfläche behoben
              werden. Es kam ein kalkhaltiges System an den Decken- und
              Wandflächen der Raumschale zur Anwendung, das durch eine
              Modifizierung spannungsarme Parameter aufwies. Das Ergebnis der
              Raumschalenfassung fand großen Zuspruch bei der Gemeinde und
              Anerkennung von den Vertretern der betreuenden Behörden.
            </p>

            <h4 className="referenceH4">Auftraggeber</h4>
            <p className="referenceText">Kath. Kirchenverwaltung Beidl</p>

            <h4 className="referenceH4">Fachbehörden</h4>
            <p className="referenceText">
              Architekturbüro Gerfried Mühlbauer und Betreuung durch das
              Bayerische Landesamt für Denkmalspflege Referatsleiter Bernhard
              Symank
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
